import React from 'react';
import {withStyles} from '@material-ui/core';
import styles from 'components/commonStyles';
import PropTypes from 'prop-types';
import {graphql, StaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash/get';
import classnames from 'classnames';
import {navigate} from '@reach/router';

const Case8 = ({classes}) => (
  <StaticQuery
    query={graphql`
      query {
        image: allFile(
          filter: { relativePath: { eq: "other/api-chooser/8.png" } }
        ) {
          edges {
            node {
              id
              childImageSharp {
                id
                fluid(
                  srcSetBreakpoints: [200, 340, 520, 800, 890]
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const img = get(data, 'image.edges[0].node.childImageSharp.fluid', {});

      const linkColor = classnames(classes.textColor, classes.link);

      const clickButton1 = () => {
        navigate('#simple-articles-provider');
      };

      const clickButton2 = () => {
        navigate('#regular-pos');
      };

      return (
        <div className={classes.case} id="pos-server-provides-data">
          <div className={classes.head}>
            <h4 className={classes.h4Absolute}>
              Use Case #8. POS server provides data
            </h4>
          </div>
          <p className={classes.textHead} style={{marginBottom: 60}}>
            You have a server with data from your POS to be integrated with
            srv4pos.com where your server is a source of the articles/sales and
            srv4pos.com is the destination.
          </p>
          <Img fluid={img} style={{maxWidth: 705}} />
          <p style={{marginTop: 60, marginBottom: 20}}>
            To send taxes, categories, products and commodities data to
            srv4pos.com see
{" "}
            <span className={linkColor} onClick={clickButton1}>
              Use Case #2. Simple Articles provder
            </span>
            .
          </p>
          <p>
            To provide sales use mecahnism used at{" "}
            <span className={linkColor} onClick={clickButton2}>
              Use Case #5. Regular POS
            </span>
            .
          </p>
        </div>
      );
    }}
  />
);

Case8.propTypes = {
  classes: PropTypes.shape({
    case: PropTypes.string.isRequired,
    head: PropTypes.string.isRequired,
    h4Absolute: PropTypes.string.isRequired,
    textHead: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, {withTheme: true})(Case8);
